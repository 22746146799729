import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Aanbod = () => {
  return (
    <Layout>
      <Seo title="Mijn aanbod" />
      <article>
        <h1>Mijn aanbod</h1>
        <p>
          Ik geef begeleiding en therapie aan (jong)volwassenen en ouderen
          (18-65+) met onderstaande klachten:
        </p>
        <ul>
          <li>piekeren, depressie;</li>
          <li>burn-out, stress;</li>
          <li>omgaan met rouw en verlieservaringen;</li>
          <li>angst- en paniekaanvallen;</li>
          <li>persoonlijkheidsproblematieken;</li>
          <li>assertiviteit, minderwaardigheidsgevoelens en onzekerheid;</li>
          <li>communicatieproblemen;</li>
          <li>
          Psychodiagnostiek: intelligentie-, ASS- en AD(H)D-onderzoek.
          </li>
        </ul>
        <p>
          Indien je bij mij niet terecht kan voor begeleiding, zoeken we samen
          naar een gepaste doorverwijzing.
        </p>
      </article>
    </Layout>
  );
};

export default Aanbod;
